import React from "react"
import { Link } from "gatsby"
import MediaQuery, { useMediaQuery } from "react-responsive"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Layout from "../components/layout"
import dudsGIF from "../images/gifs/concessions/duds.gif"
import crunchGIF from "../images/gifs/concessions/buncha.gif"
import popcornGIF from "../images/gifs/concessions/popcorn.gif"
import signGIF from "../images/gifs/sign.gif"
import backToVideo from "../images/BacktoVideoStoreRight.jpg"
import Loader from "../components/loader"

const ConcessionsPage = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  return (
    <Layout old scroll>
      <Helmet>
        <script src="https://unpkg.com/@webcomponents/webcomponentsjs@2.4.3/webcomponents-loader.js" />
        <script src="https://captaincodeman.github.io/gif-player/components/gif-player/dist/gif-player.es6.js" />
      </Helmet>
      <SEO title="Concessions" />
      <Loader>
        <div
          className="flex items-center justify-center flex-col"
          style={{ width: "56.25vh", maxWidth: "95%" }}
        >
          <Link
            to="/"
            className="w-5/6 sm:w-3/4 z-10 mt-4 flex items-center justify-center mb-8"
            state={{ noLoad: true }}
          >
            <img
              src={signGIF}
              alt="Movie Theatre Sign Reading Dillon Dowdell"
              className="w-full"
              draggable={false}
            />
          </Link>
          {isMobile ? (
            <>
              <img
                src={dudsGIF}
                alt="Movie Theatre Sign Reading Dillon Dowdell"
                className="w-3/4 mb-12"
              />
              <img
                src={crunchGIF}
                alt="Movie Theatre Sign Reading Dillon Dowdell"
                className="w-3/4 mb-12 md:mb-16"
              />
              <img
                src={popcornGIF}
                alt="Movie Theatre Sign Reading Dillon Dowdell"
                className="w-3/4"
              />
            </>
          ) : (
            <>
              <gif-player
                src={dudsGIF}
                alt="Movie Theatre Sign Reading Dillon Dowdell"
                class="w-3/4 mb-12"
                play
              />
              <gif-player
                src={crunchGIF}
                alt="Movie Theatre Sign Reading Dillon Dowdell"
                class="w-3/4 mb-12 md:mb-16"
                play
              />
              <gif-player
                src={popcornGIF}
                alt="Movie Theatre Sign Reading Dillon Dowdell"
                class="w-3/4"
                play
              />
            </>
          )}
        </div>
        <div className="flex flex-col items-center w-full mt-12">
          <MediaQuery maxWidth={639}>
            <div className="w-full flex justify-center py-4 z-30">
              <Link
                className="flex flex-row justify-end"
                style={{ width: "56.25vh", maxWidth: "95%" }}
                to="/"
                state={{ noLoad: true }}
              >
                <img
                  src={backToVideo}
                  className="h-12"
                  draggable="false"
                  alt=""
                />
              </Link>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={640}>
            <div className="w-full flex justify-center py-4 px-16 z-30">
              <Link
                className="flex flex-row justify-end w-full"
                to="/"
                state={{ noLoad: true }}
              >
                <img
                  src={backToVideo}
                  className="h-20 dillon-hover"
                  draggable="false"
                  alt=""
                />
              </Link>
            </div>
          </MediaQuery>
        </div>
      </Loader>
    </Layout>
  )
}

export default ConcessionsPage
